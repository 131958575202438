import { ExclamationCircleOutlined } from '@ant-design/icons';
import { NextUIProvider } from "@nextui-org/react";
import { ConfigProvider, Modal, Spin, theme } from 'antd';
import { createContext, useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ChatHistory from './screens/chat/chat-history';
import Administration from './screens/administration/administration';
import AssignedStudents from './screens/assigned-students/assigned-students';
import Header from "./components/header";
import HiddenWhenMobile from './screens/hidden-when-mobile';
import { AbsoluteCenter } from './components/styles';
import ChatInterface from './screens/chat/chat-interface';
import StudentCourseOverview from './screens/student-course-overview';
import Course from './screens/courses/course';
import CourseOverview from './screens/courses/course-overview';
import LoginScreen from './screens/login/login';
import SignupScreen from './screens/signup/signup';
import { fetchUserType, verifyToken } from './services/user';
import { getUserDataFromLocalStorage } from './utils/useLocalStorage';
import { getAnalyticsCopilotInitialMessage } from './utils/utils';
import { CourseAnalytics } from './screens/analytics/course-analytics';
import { dark_theme_token, default_theme_token, dark_theme_components, default_theme_components } from './config';
import { logout } from './services/auth-axios';
import { ErrorBoundary } from "react-error-boundary";
import FallbackComponent from './components/fallback';

const { confirm } = Modal;

export const UserContext = createContext(null);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const checkAuthStatus = async () => {
    const isValidToken = await verifyToken();
    setIsLoggedIn(isValidToken);
    if (isValidToken) {
      // Optionally fetch and set user type here if your verification endpoint provides this information
      const userData = getUserDataFromLocalStorage();
      setUser(userData)
      if (userData?._id) {
        fetchUserType(userData._id).then(setUserType);
      }
    }
  };
  useEffect(() => {
    checkAuthStatus();
  }, []);


  const loginCallback = () => {
    checkAuthStatus()
  };

  // Function to handle user logout
  const handleLogout = () => {
    logout();
    setIsLoggedIn(false);
  };

  const confirmLogout = () => {
    confirm({
      title: 'Are you sure you want to logout?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleLogout()
      },
      onCancel() {
        setLogoutOpen(false);
      },
    });
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setIsDarkMode(savedTheme === 'dark');
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'default');
  };

  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={(error, info) => {
        // Log the error to an error reporting service
        console.error("Caught an error:", error, info);
      }}
      onReset={() => {
        // Reset the state of your app
        window.location.reload();
      }}>
      <NextUIProvider>
        <ConfigProvider theme={{
          algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
          token: isDarkMode ? dark_theme_token : default_theme_token,
          components: isDarkMode ? dark_theme_components : default_theme_components,
        }}>
          <main className={`${isDarkMode ? 'dark' : ''} text-foreground bg-background`} style={{ height: '100vh' }}>
            <HiddenWhenMobile />
            {isLoggedIn ? (
              <>
                {userType ?
                  <>
                    <UserContext.Provider value={{ type: userType, ...user }}>
                      <Router>
                        <Header onLogout={() => confirmLogout()} isDarkMode={isDarkMode} toggleTheme={toggleTheme}>
                          <Routes>
                            <Route path="/" element={<Navigate replace to="/courses" />} />
                            <Route path="/courses" element={userType === 'student' ? <StudentCourseOverview /> : <CourseOverview />} />
                            <Route path="/courses/:userID/get_user_courses" element={<CourseOverview />} />
                            <Route path="/courses/:courseID" element={<Course />} />
                            <Route path="/chat/:chatID" element={<ChatInterface type="content" endpointUrl="conversations" />} />
                            <Route path="/chat-history" element={<ChatHistory />} />
                            <Route path="/assigned-students" element={<AssignedStudents endpointUrl="assigned-students" />} />
                            <Route path="/administration" element={<Administration endpointUrl="administration" />} />
                            <Route path="/analytics-copilot/:chatID"
                              element={<ChatInterface
                                type="analytics"
                                endpointUrl="analytics-copilot"
                                initialMessage={getAnalyticsCopilotInitialMessage()}
                              />} />
                            <Route path="/analytics/:courseID" element={<CourseAnalytics />} />
                            <Route path="*" element={<Navigate replace to="/" />} />
                          </Routes>
                        </Header>
                      </Router >
                    </UserContext.Provider>
                  </>
                  : <AbsoluteCenter><Spin size="large" /></AbsoluteCenter>
                }
              </>
            ) : (
              <Router>
                <Routes>
                  <Route path="/login" element={<LoginScreen loginCallback={loginCallback} isDarkMode={isDarkMode} toggleTheme={toggleTheme} />} />
                  <Route path="/signup" element={<SignupScreen loginCallback={loginCallback} isDarkMode={isDarkMode} toggleTheme={toggleTheme} />} />
                  <Route path="*" element={<Navigate replace to="/login" />} />
                </Routes>
              </Router >
            )}
          </main>
        </ConfigProvider >
      </NextUIProvider>
    </ErrorBoundary>
  );
}

export default App;