import { Alert, Button, Form, Input, Select, message } from 'antd';
import { useFormik } from 'formik';
import { useState } from 'react';

const SignupForm = ({ loginCallback }) => {
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            educator_email: '',
            userType: '',
        },
        validate: (values) => {
            let errors = {};
            if (!values.name) {
                errors.name = 'Please enter your name.';
            }
            if (!values.email) {
                errors.email = 'Please enter your email.';
            }
            if (!values.password) {
                errors.password = 'Please enter your password.';
            }
            if (!values.educator_email) {
                errors.educator_email = 'Please enter the email of your educator.';
            }
            return errors;
        },
        onSubmit: (values) => {
            setIsLoading(true);
            // Perform login logic by sending data to the backend
            fetch(`${process.env.REACT_APP_BACKEND_URL}/signup/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: values.name,
                    email: values.email,
                    username: values.email,
                    password: values.password,
                    educator_email: values.educator_email
                    // do not send type as default is student
                }),
            })
                .then((response) => {
                    if (!response.ok) {
                        if (response.status === 403) {
                            return response.json().then((errorData) => {
                                console.error(response);
                                throw new Error("This educator cannot accept more students at the moment");
                            });
                        }
                        else {
                            return response.json().then((errorData) => {
                                console.error(response);
                                throw new Error("Seems like this isn't working. Please double check your educators email address and try again! ");
                            });

                        }
                    }
                    return response.json();
                })
                .then((data) => {
                    localStorage.setItem('user', JSON.stringify(data.user));
                    localStorage.setItem('access_token', data.access_token);
                    loginCallback();
                })
                .catch((error) => {
                    message.error(error.message || 'Signup Failed!');
                }).finally(() => {
                    setIsLoading(false);
                })

        },
        validateOnChange: false,  // Prevent automatic validation on change
        validateOnBlur: false
    });

    return (
        <>
            <Form onSubmitCapture={formik.handleSubmit} style={{ width: "100%" }}>
                <Form.Item
                    help={formik.errors.name}
                    validateStatus={formik.errors.name ? "error" : undefined}
                >
                    <Input
                        size="large"
                        type="text"
                        id="name"
                        placeholder='Enter your name'
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Form.Item>

                <Form.Item
                    help={formik.errors.email}
                    validateStatus={formik.errors.email ? "error" : undefined}
                >
                    <Input
                        size="large"
                        type="text"
                        id="email"
                        placeholder='Enter your email'
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Form.Item>

                <Form.Item
                    help={formik.errors.password}
                    validateStatus={formik.errors.password ? "error" : undefined}
                >
                    <Input.Password
                        size="large"
                        id="password"
                        placeholder='Enter your password'
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Form.Item>

                <Form.Item
                    help={formik.errors.email}
                    validateStatus={formik.errors.email ? "error" : undefined}
                >
                    <Input
                        size="large"
                        type="text"
                        id="educator_email"
                        placeholder='Enter the email of your educator'
                        name="educator_email"
                        value={formik.values.educator_email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </Form.Item>

                <Button loading={isLoading} type="primary" htmlType="submit" size="large" style={{ width: "100%" }}>Sign up</Button>
            </Form>
            {formik.errors.general && (
                <Alert
                    style={{ marginTop: '16px' }}
                    message={formik.errors.general}
                    type="error"
                    showIcon
                />
            )}
        </>
    );
};

export default SignupForm;
