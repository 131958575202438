import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import { styled } from "@stitches/react";
import { Avatar, Button, message, theme } from "antd";
import 'katex/dist/katex.min.css'; // Import KaTeX CSS
import { useEffect, useState } from "react";

import MarkdownView, { ShowdownExtension } from "react-showdown";
// @ts-ignore
import showdownKatex from 'showdown-katex';
import { dislikeMessage, likeMessage } from "../../services/conversations";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";
import { preprocessLatex } from "../../utils/utils";
import { atla_logo_path, chatFormatContent, sona_logo_path } from "../../config";


const MessageBubbleContainer = styled("div", {
    maxWidth: '100%', // Ensure the bubble doesn't exceed the container width
    flexShrink: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "10px",
    padding: "10px",

    "& #answer > :first-child ": {
        marginTop: "0 !important",
        paddingTop: "0 !important",
    },
});


const TextContentContainer = styled("div", {
    width: '100%',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    '& .markdown-body': {
        fontFamily: 'inherit', // Use the inherited font family
        fontSize: 'inherit', // Use the inherited font size
        lineHeight: 'inherit', // Use the inherited line height
        '& > :first-child': {
            marginTop: '0 !important',
            paddingTop: '0 !important',
        },
        '& > :last-child': {
            marginBottom: '0 !important',
            paddingBottom: '0 !important',
        },
    },
});

const LikeDislikeContainer = styled("div", {
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem"
});

const Flex = styled("div", {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem"
})


export const MessageBubble = ({
    conversationId,
    msgId,
    user,
    text,
    type }: {
        conversationId: string,
        msgId: string,
        user: "user" | "bot" | "system",
        text: string,
        type: string
    }) => {
    const { token } = theme.useToken();
    const userData = getUserDataFromLocalStorage();
    const [logoData, setLogoData] = useState<string | undefined>(undefined);
    const [parsedText, setParsedText] = useState<string>(text);
    const [likeLoading, setLikeLoading] = useState<boolean>(false);
    const [dislikeLoading, setDislikeLoading] = useState<boolean>(false);

    useEffect(() => {
        setParsedText(preprocessLatex(text));
    }, [text]);

    useEffect(() => {
        setLogoData(userData.config.logo);
    }, [user, userData.config.logo]);

    function handleLike() {
        setLikeLoading(true);
        likeMessage(conversationId, text)
            .then(() => {
                message.success("Thank you for the feedback!");
            })
            .catch(err => {
                message.error("Error liking message: " + err);
                console.error(err);
            })
            .finally(() => setLikeLoading(false));
    }

    function handleDislike() {
        setDislikeLoading(true);
        dislikeMessage(conversationId, text)
            .then(() => {
                message.success("Thank you for the feedback!");
            })
            .catch(err => {
                message.error("Error disliking message: " + err);
                console.error(err);
            })
            .finally(() => setDislikeLoading(false));
    }

    return (
        <MessageBubbleContainer>
            <Avatar
                size="small"
                src={(user === "bot" || user === "system") && (type === chatFormatContent ? sona_logo_path : atla_logo_path)}
                style={{
                    flexShrink: 0,
                    alignSelf: 'flex-start',
                    marginTop: "-3px",
                    backgroundColor: user === "user" ? token.colorBgSpotlight : "transparent"
                }}
            >
                {user === "user" && userData.name[0]}
            </Avatar>
            <Flex>
                <b>{user === "user" ? "You" : (type === chatFormatContent ? "Sona" : "Atla")}</b>
                <TextContentContainer>
                    <MarkdownView
                        markdown={parsedText}
                        options={{ tables: true, emoji: true }}
                        extensions={showdownKatex({
                            displayMode: true,
                            throwOnError: false,
                            errorColor: '#ff0000',
                            delimiters: [
                                { left: "$$", right: "$$", display: true },
                                { left: "\\[", right: "\\)", display: true },
                                { left: "\\(", right: "\\)", display: true },
                                { left: "$", right: "$", display: false }
                            ]
                        }) as unknown as ShowdownExtension[]}
                    />
                </TextContentContainer>
                {user === "bot" && (
                    <LikeDislikeContainer>
                        <Button loading={likeLoading} type="text" icon={<LikeOutlined />} onClick={handleLike} />
                        <Button loading={dislikeLoading} type="text" icon={<DislikeOutlined />} onClick={handleDislike} />
                    </LikeDislikeContainer>
                )}
            </Flex>
        </MessageBubbleContainer>
    );
};