import { CalendarOutlined, LoadingOutlined, QuestionCircleOutlined, UpCircleOutlined, UpCircleFilled } from "@ant-design/icons";
import { styled } from "@stitches/react";
import { Button, Col, Divider, Layout, Menu, MenuProps, Modal, Row, Tooltip } from "antd";
import 'katex/dist/katex.min.css'; // Import KaTeX CSS
import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { theme } from 'antd';

import { v4 as uuidv4 } from 'uuid';
import { Conversation, Message, getConversation } from "../../services/conversations";
import { Course, getCourse, Week } from "../../services/courses";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";
import { capitalize } from "../../utils/utils";
import authedAxios from "../../services/auth-axios";
import TemplateSider from "../../components/chat/TemplateSider";
import AssignmentSelectionOverlay from "../../components/chat/AssignmentSelectionOverlay";
import { MessageBubble } from "../../components/chat/MessageBubble";
import { Template } from "../../services/templates";
import { SendButton, SendContainer, SendInput } from "../../components/basic/send-container";
import CourseReportsPage from "../analytics/CourseReportsPage";

const chatSiderWidth = "33vh";

const { Content } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const Container = styled(Content, {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    height: "100vh",
    // minHeight: "100vh",
    // width: "100vh",
    width: `calc(100vh - ${chatSiderWidth} / 2)`, // Using calc() to subtract chatSiderWidth from 100vh
    // maxHeight: "100vh",
    // maxWidth: "800px",
    margin: "0 auto",
    gap: "10px",
    padding: "2rem",
    boxSizing: "border-box",
})

const MessagesContainer = styled("div", {
    width: '80vh',
    maxWidth: '100%',
    height: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
});

const HeaderContainer = styled("div", {
    display: "flex",
    width: '70vh',
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px", // Adjust padding as necessary
    borderBottom: "1px solid #e0e0e0", // Optional bottom border for separation
    minHeight: "60px", // Adjust as necessary for header height
    boxSizing: "border-box",
});

type ChatInterfaceProps = {
    type: string;
    endpointUrl: string;
    initialMessage?: string;
};

const ChatInterface = ({ type: chatTypeSpecifier, endpointUrl: endpointUrlSpecifier, initialMessage: initialMessageSpecifier }: ChatInterfaceProps) => {
    const { chatID } = useParams();
    const userData = getUserDataFromLocalStorage();
    const [messages, setMessages] = useState<Message[]>([])
    const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(false)
    const [input, setInput] = useState<string>("")
    const [conversation, setConversation] = useState<Conversation | undefined>(undefined)

    const [course, setCourse] = useState<Course | undefined>(undefined)

    const [weekMenuItems, setWeekMenuItems] = useState<MenuItem[]>([]);
    const [selectedWeeks, setSelectedWeeks] = useState<number[]>([]);

    const [groupMenuItems, setGroupMenuItems] = useState<MenuItem[]>([]);
    const [selectedGroups, setSelectedGroup] = useState<string[]>([]);

    const [materialMenuItems, setMaterialMenuItems] = useState<MenuItem[]>([]);
    const [selectedMaterials, setSelectedMaterials] = useState<string[]>([]);

    const [templates, setTemplates] = useState<Template[]>([]);

    const [error, setError] = useState<string | null>(null);
    const [overlayVisible, setOverlayVisible] = useState<boolean>(false); // State for overlay visibility
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const { useToken } = theme;
    const { token } = useToken();

    const sider_tooltip = <span>{`Use the templates to quickly use your favourte prompts. Filter the response content by ${userData.config.course_terminology.toLowerCase()} ${userData.config.module_terminology.toLowerCase()}.`}</span>;
    const main_tooltip = (
        <span>
            What can you do with this AI? <br /><br />
            {`You can chat with all the documents uploaded by your educators to the selected ${userData.config.module_terminology.toLowerCase()}s. If no ${userData.config.module_terminology.toLowerCase()} is selected, you are chatting to all the information available to the system. `}<br />
            {`Answers will provide the source, including a page number or timestamp, the relevant ${userData.config.module_terminology.toLowerCase()}, and a relevancy score. The relevancy score may not align with your perspective as it is an auto-calculated score. `}<br /><br />
            You can further ask the AI: <br />
            <ul>
                <li>{`for a summary of the selected ${userData.config.module_terminology.toLowerCase()}s.`}</li>
                <li>{`for a list of the documents in the selected ${userData.config.module_terminology.toLowerCase()}s.`}</li>
                <li>{`the names of the educators of the ${userData.config.course_terminology.toLowerCase()}.`}</li>
            </ul><br />
            Do you think a function is missing? Reach out to us using the feedback button in the bottom left. We are eager to improve!
        </span>
    );

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    useEffect(() => {
        setIsLoadingMessages(true)
        getConversation(chatID!).then(c => {
            setConversation(c)
        })
    }, [chatID])


    useEffect(() => {
        if (!conversation) return;
        getCourse(conversation.course_id!).then((course) => {
            setIsLoadingMessages(true);
            setCourse(course);
            setIsLoadingMessages(false);
        });
        // Update templates to ensure each has a ui_key
        const updatedTemplates = (conversation.templates || [])
            .filter(template => template.type === chatTypeSpecifier) // Filter by ChatInterface type
            .map(template => ({
                ...template,
                ui_key: template.ui_key || uuidv4()
            }));
        setTemplates(updatedTemplates);
    }, [conversation])

    useEffect(() => {
        if (!conversation) return;
        setIsLoadingMessages(true);
        let initialMessage = {
            state_id: uuidv4(),
            msg_id: uuidv4(),
            user: "system",
            text: initialMessageSpecifier ? initialMessageSpecifier : `Hello ${userData.name}, how are you?\n\nWhat would like to learn today?`,
            timestamp: new Date().toISOString()
        } as Message

        setMessages(
            (conversation.messages && conversation.messages.length > 0)
                ? conversation.messages
                : [initialMessage]
        );
        setIsLoadingMessages(false);
    }, [conversation])

    useEffect(() => {
        if (course) {
            const currentDate = new Date();
            const relevantWeekItems = course.weeks
                .filter(week => new Date(week.start_date) <= currentDate)
                .map((week) => ({
                    label: `${capitalize(userData.config.module_terminology)} ${week.number}`,
                    key: week.number,
                }));

            // Prepend "Select All" to the top if there are at least two weeks
            if (relevantWeekItems.length >= 2) {
                relevantWeekItems.unshift({
                    label: 'Select All',
                    key: 0, // Key for "Select All"
                });
            }

            let newWeekMenuItems = [{
                label: `Select ${capitalize(userData.config.module_terminology)}s`,
                key: 'select-modules',
                children: [
                    ...relevantWeekItems,
                ],
                icon: <CalendarOutlined />,
            }];

            if (relevantWeekItems.length > 0) {
                const indexToSelect = Math.min(1, relevantWeekItems.length - 1);
                let initialSelectedWeeks = [relevantWeekItems[indexToSelect].key as number];
                setSelectedWeeks(initialSelectedWeeks);
                updateRelevantWeekNumbers(initialSelectedWeeks);
            } else {
                setSelectedWeeks([]);
            }
            setWeekMenuItems(newWeekMenuItems);
        }
    }, [course]);

    const onWeekSelectionClick: MenuProps['onClick'] = (e) => {
        let updatedSelectedWeeks = [...selectedWeeks];
        const clickedWeekNumber = parseInt(e.key);

        if (clickedWeekNumber === 0) {
            // "Select All" logic
            if (course && updatedSelectedWeeks.length === course.weeks.length) {
                // If all weeks are already selected, deselect all
                updatedSelectedWeeks = [];
            } else {
                // Else, select all weeks
                updatedSelectedWeeks = course ? course.weeks.map((_, index) => index + 1) : [];
            }
        } else {
            // Individual week selection logic
            if (updatedSelectedWeeks.includes(clickedWeekNumber)) {
                // If already selected, remove from selection
                updatedSelectedWeeks = updatedSelectedWeeks.filter(week => week !== clickedWeekNumber);
            } else {
                // If not selected, add to selection
                updatedSelectedWeeks.push(clickedWeekNumber);
            }
        }

        if (updatedSelectedWeeks.length === 0 && course!.weeks.length > 1) {
            if (clickedWeekNumber === 0) {
                // If all weeks are deselected via Select All, select the last week
                updatedSelectedWeeks.push(course!.weeks.length);
            } else {
                // If no weeks are selected, select the clicked week
                updatedSelectedWeeks.push(clickedWeekNumber);
            }
        }

        setSelectedWeeks(updatedSelectedWeeks); // Update state
        updateRelevantWeekNumbers(updatedSelectedWeeks); // Call backend with the updated list
        // Update group menu items
        const selectedWeekData = course!.weeks.filter(week => updatedSelectedWeeks.includes(week.number));

        generateMenuItems(selectedWeekData);
    };

    function generateMenuItems(selectedWeekData: Week[]) {
        const uniqueGroups = Array.from(new Set(selectedWeekData.flatMap(week =>
            week.materials ? week.materials.map(material => material.group).filter(group => group !== "") : []
        )));

        const newGroupMenuItems = [{
            label: `Select Groups`,
            key: 'select-groups',
            children: uniqueGroups.map(group => ({
                label: `Group ${group}`,
                key: group,
            })),
            icon: <CalendarOutlined />,
        }];
        setGroupMenuItems(newGroupMenuItems);

        const allMaterials = selectedWeekData.flatMap(week => week.materials || []);
        const uniqueMaterialsMap = new Map(allMaterials.map(material => [material.file_id, material]));

        const newMaterialMenuItems = [{
            label: `Select Materials`,
            key: 'select-materials',
            children: Array.from(uniqueMaterialsMap.values()).map(material => ({
                label: material.name,
                key: material.file_id,
            })),
            icon: <CalendarOutlined />,
        }];

        setMaterialMenuItems(newMaterialMenuItems);
    };

    const onGroupSelectionClick: MenuProps['onClick'] = (e) => {
        let updatedSelectedGroups = [...selectedGroups];
        const clickedGroup = e.key;

        if (updatedSelectedGroups.includes(clickedGroup)) {
            // If already selected, remove from selection
            updatedSelectedGroups = updatedSelectedGroups.filter(group => group !== clickedGroup);
        } else {
            // If not selected, add to selection
            updatedSelectedGroups.push(clickedGroup);
        }

        setSelectedGroup(updatedSelectedGroups); // Update state
        updateRelevantGroups(updatedSelectedGroups); // Call backend with the updated list
    };

    const onMaterialSelectionClick: MenuProps['onClick'] = (e) => {
        let updatedSelectedMaterials = [...selectedMaterials];
        const clickedMaterialFileId = e.key;

        if (updatedSelectedMaterials.includes(clickedMaterialFileId)) {
            // If already selected, remove from selection
            updatedSelectedMaterials = updatedSelectedMaterials.filter(fileId => fileId !== clickedMaterialFileId);
        } else {
            // If not selected, add to selection
            updatedSelectedMaterials.push(clickedMaterialFileId);
        }

        setSelectedMaterials(updatedSelectedMaterials); // Update state
        updateRelevantFileIds(updatedSelectedMaterials); // Call backend with the updated list
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault(); // Prevent the default action (new line)
            sendMessage();
        }
    };

    const handleSetMessageText = (text: string) => {
        setInput(text);  // Set the input state which is bound to the message input field
    };

    function sendMessage() {
        if (input === "") return;

        let new_msg_id = uuidv4()
        const newUserMessage = { state_id: uuidv4(), msg_id: new_msg_id, user: "user", text: input, timestamp: new Date().toISOString() } as Message;

        setInput("");
        setIsLoadingMessages(true);

        setMessages(prevMessages => [...prevMessages, newUserMessage]);

        const token = localStorage.getItem('access_token');
        if (!token) {
            throw new Error("No access token available");
        }

        (async () => {
            try {
                const textDecoder = new TextDecoder();
                const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${endpointUrlSpecifier}/${chatID}/chat?user_query=${encodeURIComponent(input)}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (res.body) {
                    const reader = res.body.getReader();

                    while (true) {
                        const { value, done } = await reader.read();
                        if (done) break;

                        const text_value = textDecoder.decode(value, { stream: true });

                        // Update the message with the accumulated text
                        updateOrAddMessage(new_msg_id, text_value);
                    }
                } else {
                    throw new Error("No body");
                }
            } catch (error: any) {
                console.error('Error:', error);
                setError(error.message);
            } finally {
                setIsLoadingMessages(false)
            }
        })()
    }

    function updateOrAddMessage(new_msg_id: String, new_token: String) {
        // This function updates the last message without adding a new one. This facilitates streaming in the UI.
        setMessages(prevMessages => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            const prevMessagesExceptLast = prevMessages.slice(0, prevMessages.length - 1);
            const updatedMessages = prevMessages.length > 0 && lastMessage.user === "bot"
                ? [...prevMessagesExceptLast, { state_id: uuidv4(), msg_id: new_msg_id, user: 'bot', text: lastMessage.text + new_token, timestamp: new Date().toISOString() } as Message]
                : [...prevMessages, { state_id: uuidv4(), msg_id: new_msg_id, user: 'bot', text: new_token, timestamp: new Date().toISOString() } as Message];
            return updatedMessages;
        });
    }

    async function updateRelevantWeekNumbers(weekNumbers: number[]) {
        try {
            const response = await authedAxios.put(`${process.env.REACT_APP_BACKEND_URL}/conversations/${chatID}/update_relevant_week_numbers`,
                weekNumbers,
            );

            if (response.status !== 200) {
                throw new Error("Failed to update conversation with relevant weeks");
            }

            console.log("Conversation updated successfully:", response.data);
        } catch (error) {
            console.error("Error updating conversation:", error);
        }
    }

    async function updateRelevantGroups(groups: string[]) {
        try {
            const response = await authedAxios.put(`${process.env.REACT_APP_BACKEND_URL}/conversations/${chatID}/update_relevant_groups`,
                groups,
            );

            if (response.status !== 200) {
                throw new Error("Failed to update conversation with groups");
            }

            console.log("Conversation updated successfully:", response.data);
        } catch (error) {
            console.error("Error updating conversation:", error);
        }
    }

    async function updateRelevantFileIds(file_ids: string[]) {
        try {
            const response = await authedAxios.put(`${process.env.REACT_APP_BACKEND_URL}/conversations/${chatID}/update_relevant_file_ids`,
                file_ids,
            );

            if (response.status !== 200) {
                throw new Error("Failed to update conversation with file_ids");
            }

            console.log("Conversation updated successfully:", response.data);
        } catch (error) {
            console.error("Error updating conversation:", error);
        }
    }

    function handleShowAssignmentClick() {
        setOverlayVisible(true);
    }

    const handleWeekSelect = (weekNumber: number) => {
        const selectedWeek = course?.weeks.find(week => week.number === weekNumber);
        if (selectedWeek) {
            const newMessage: Message = {
                state_id: uuidv4(),
                msg_id: uuidv4(),
                user: "bot",
                text: selectedWeek.assignment || "No assignment for this week.",
                timestamp: new Date().toISOString()
            };
            setMessages(prevMessages => [...prevMessages, newMessage]);
        }
        setOverlayVisible(false);
    };

    const handleUpdateTemplates = async (updatedTemplates: Template[]) => {
        if (conversation) {
            const templatesToSend = updatedTemplates.map(({ ui_key, ...backend_template }) => backend_template);

            try {
                const response = await authedAxios.put(`${process.env.REACT_APP_BACKEND_URL}/conversations/${conversation._id}/update_templates`, {
                    templates: templatesToSend // Send the modified templates
                });
                if (response.status === 200) {
                    console.log("Templates updated successfully");
                    setTemplates(updatedTemplates);
                    // Update the conversation object with the new templates
                    setConversation(prevConversation => ({
                        ...prevConversation!,
                        templates: updatedTemplates
                    }));
                } else {
                    console.error("Failed to update templates");
                }
            } catch (error) {
                console.error('Error updating templates:', error);
            }

            const userTemplatesToSend = [
                ...userData.templates,
                ...templatesToSend.filter(template =>
                    !userData.templates.some((userTemplate: Template) => userTemplate.name === template.name)
                ) // Include new templates
            ];

            if (userTemplatesToSend.length > 0) {
                const userResponse = await authedAxios.put(`${process.env.REACT_APP_BACKEND_URL}/users/${userData._id}/update_user_templates`, {
                    templates: userTemplatesToSend
                });

                if (userResponse.status === 200) {
                    console.log("User templates updated successfully");
                } else {
                    console.error("Failed to update user templates");
                }
            }
        }
    };

    return (<>
        <Layout style={{ height: '100vh' }}>
            <Layout.Content>
                <Container>
                    <MessagesContainer>
                        {course &&
                            <HeaderContainer>
                                <div style={{ width: '50%' }}>
                                    <h1 style={{ margin: 0, fontSize: '24px' }}>{course.name}</h1>
                                </div>
                                <div style={{
                                    width: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: "nowrap",
                                    maxWidth: "100%",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    padding: "0px 0px",
                                }}>
                                    <Button
                                        style={{
                                            flexShrink: 0,
                                            height: '100%',
                                            width: '80%'
                                        }}
                                        onClick={handleShowAssignmentClick}
                                    >
                                        {chatTypeSpecifier === 'analytics' ? 'Reports' : 'Assignments'}
                                    </Button>
                                    <Tooltip placement="bottomRight" title={main_tooltip} overlayStyle={{ maxWidth: '50vh' }}>
                                        <Button
                                            icon={<QuestionCircleOutlined />}
                                            style={{
                                                flexShrink: 0,
                                                height: '100%',
                                                margin: 5,
                                                width: '20%',
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            </HeaderContainer>
                        }
                        {messages.map((msg) => {
                            return (
                                <MessageBubble
                                    key={msg.state_id}
                                    conversationId={conversation!._id}
                                    msgId={msg.msg_id}
                                    user={msg.user}
                                    text={msg.text}
                                    type={chatTypeSpecifier}
                                />
                            );
                        })}
                        <div ref={messagesEndRef} /> {/* Dummy div for scrolling */}
                    </MessagesContainer>
                    <SendContainer>
                        <div style={{ position: "relative" }}>
                            <SendInput size="large"
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                placeholder="Send message..."
                                value={input}
                                onChange={e => setInput(e.target.value.replace(/^\s+/, ''))}
                                onKeyDown={(e) => handleKeyDown(e)}
                            />
                            <Tooltip
                                title={isLoadingMessages ? "Please wait while the message is being processed" :
                                    weekMenuItems.length === 1 ? "No materials are currentely available. Please check again later." :
                                        selectedWeeks.length === 0 ? `Please select at least one ${userData.config.module_terminology}` :
                                            ""}
                            >
                                <SendButton
                                    data-testid="send-button"
                                    size="large"
                                    type="text"
                                    disabled={isLoadingMessages || selectedWeeks.length === 0}
                                    onClick={() => { sendMessage() }}
                                    style={{ transition: 'background-color 0.3s ease', color: token.colorPrimary }}
                                    onMouseEnter={(event) => {
                                        event.currentTarget.style.backgroundColor = 'transparent';
                                        event.currentTarget.style.color = token.colorPrimaryHover;
                                    }}
                                    onMouseLeave={(event) => {
                                        event.currentTarget.style.backgroundColor = 'transparent';
                                        event.currentTarget.style.color = token.colorPrimary;
                                    }}
                                >
                                    {isLoadingMessages
                                        ? <LoadingOutlined style={{ color: token.colorPrimary }} /> : input.length > 0
                                            ? <UpCircleFilled style={{ fontSize: '24px' }} />
                                            : <UpCircleOutlined style={{ fontSize: '24px' }} />}
                                </SendButton>
                            </Tooltip>
                        </div>
                    </SendContainer>
                </Container>

                {chatTypeSpecifier === 'content' ?
                    <AssignmentSelectionOverlay
                        visible={overlayVisible}
                        weeks={course ? course.weeks : []}
                        onWeekSelect={handleWeekSelect}
                        onClose={() => setOverlayVisible(false)}
                    />
                    :
                    <Modal
                        title="Course Reports"
                        open={overlayVisible}
                        onCancel={() => setOverlayVisible(false)}
                        footer={null}
                        width="60%"
                        destroyOnClose={true}
                        style={{ 
                            display: 'flex', 
                            top: 20, 
                            justifyContent: 'center' 
                        }}
                    >
                        {process.env.REACT_APP_COURSE_IDS?.split(',').includes(userData._id!) ? (
                            <CourseReportsPage />
                        ) : (
                            <div>Coming soon!</div>
                        )}
                    </Modal>
                }
            </Layout.Content>
            <Layout.Sider width={chatSiderWidth} style={{ overflow: 'auto', overflowX: 'hidden' }}>
                <Row justify="space-between">
                    <Col span={22} push={1}>
                        <div
                            style={{
                                textAlign: 'left',
                                width: '90%',
                                height: '100%',
                                border: 'none',
                                boxShadow: 'none',
                                color: 'inherit',
                                backgroundColor: 'transparent'
                            }}
                        >
                            <h3>Side Action Bar</h3>
                        </div>
                    </Col>
                    <Col span={2}>
                        <Tooltip placement="left" title={sider_tooltip}>
                            <Button
                                icon={<QuestionCircleOutlined />}
                                style={{
                                    flexShrink: 0,
                                    height: '100%',
                                    border: 'none',
                                    padding: 0,
                                    width: '10%',
                                }}
                            />
                        </Tooltip>
                    </Col>
                </Row>
                <TemplateSider
                    onSetMessageText={handleSetMessageText}
                    templates={templates ?? []}
                    onUpdateTemplates={handleUpdateTemplates}
                    chatTypeSpecifier={chatTypeSpecifier}
                />
                <Divider
                    style={{ margin: 5 }}>
                </Divider>
                {course && course.weeks.length > 0 &&
                    (
                        <div style={{
                            // maxHeight: '50vh', 
                            overflow: 'visible'
                        }}>
                            <Menu
                                style={{
                                    // maxHeight: '50vh', 
                                    overflow: 'visible'
                                }}
                                onClick={onWeekSelectionClick}
                                selectedKeys={selectedWeeks.map(week => week.toString())}
                                defaultOpenKeys={['select-modules']}
                                mode="inline"
                                items={weekMenuItems} />
                        </div>
                    )
                }
                {chatTypeSpecifier === "content" && course && course.weeks.length > 0 && selectedWeeks.length > 0 &&
                    (
                        <div style={{
                            // maxHeight: '50vh', 
                            overflow: 'visible'
                        }}>
                            {userData.config.filter_by_group &&
                                <Menu
                                    style={{
                                        // maxHeight: '50vh', 
                                        overflow: 'visible'
                                    }}
                                    onClick={onGroupSelectionClick}
                                    selectedKeys={selectedGroups.map(group => group.toString())}
                                    defaultOpenKeys={['select-groups']}
                                    mode="inline"
                                    items={groupMenuItems} />
                            }
                            {userData.config.filter_by_source &&
                                <Menu
                                    style={{
                                        // maxHeight: '50vh', 
                                        overflow: 'visible'
                                    }}
                                    onClick={onMaterialSelectionClick}
                                    selectedKeys={selectedMaterials.map(material => material.toString())}
                                    defaultOpenKeys={['select-materials']}
                                    mode="inline"
                                    items={materialMenuItems} />
                            }
                        </div>
                    )
                }
            </Layout.Sider>
        </Layout>
    </>)
}


export default ChatInterface;