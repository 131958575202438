// api.ts
import { UploadFile } from "antd";
import authedAxios from "./auth-axios";
import { getUserDataFromLocalStorage } from "../utils/useLocalStorage";
import { Template } from "./templates";

export interface Professor {
    _id: string;
    name: string;
    photo: string;
    assigned_student_id_list: Student[];
}

export interface Student {
    _id: string;
    name: string;
    photo: string;
}

export type WeekMaterial = {
    name: string
    file_id: string
    spans_fully: boolean
    span_start: number | undefined
    span_end: number | undefined
    file_type: 'book' | 'slides' | 'audio'
    group: string
}

export type Week = {
    number: number
    materials: WeekMaterial[]
    topics: string[]
    assignment: string
    start_date: string
    end_date: string
    summary: string
}

export interface Course {
    _id: string;
    last_updated: string;
    created_at: string;
    name: string;
    description: string;
    professors: Professor[];
    enrolled_students: Student[];
    assigned_students: Student[];
    weeks: Week[]
    templates: Template[]
    language: string
}

export const createCourse = async (name: string, description: string, professor_id: string): Promise<Course> => {
    try {
        const response = await authedAxios.post(`${process.env.REACT_APP_BACKEND_URL}/courses`, { name, description, professor_id });
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
};

export const getCourse = async (id: string): Promise<Course> => {
    try {
        const response = await authedAxios.get(`${process.env.REACT_APP_BACKEND_URL}/courses/${id}`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
};

// load faster via course_id_list
export const getUserCourses = async (user_id: string): Promise<Course[]> => {
    try {
        const response = await authedAxios.get(`${process.env.REACT_APP_BACKEND_URL}/courses/${user_id}/get_user_courses`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
};

export const getCoursesByProfessorId = async (profid: string): Promise<Course[]> => {
    try {
        const response = await authedAxios.get(`${process.env.REACT_APP_BACKEND_URL}/courses/professor/${profid}`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
};

export const updateCourse = async (course: Course): Promise<Boolean> => {
    try {
        const response = await authedAxios.post(`${process.env.REACT_APP_BACKEND_URL}/courses/${course._id}`, {
            _id: course._id,
            last_updated: course.last_updated,
            created_at: course.created_at,
            name: course.name,
            description: course.description,
            professors: course.professors,
            enrolled_students: course.enrolled_students,
            assigned_students: course.assigned_students,
            weeks: course.weeks,
            templates: course.templates,
            language: course.language
        });
        return response.status === 200;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}

export const deleteCourse = async (course_id: string): Promise<boolean> => {
    try {
        const response = await authedAxios.delete(`${process.env.REACT_APP_BACKEND_URL}/courses/${course_id}`);
        return response.data.deleted === true;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
};

export interface CourseFile {
    _id: string;
    file_name: string;
    course_id: string;
    created_at: string;
    last_updated: string;
    user_id: string;
    number_of_pages: number;
    status: string;
}

export const deleteCourseFile = async (file_id: string, course_id: string): Promise<void> => {
    try {
        const response = await authedAxios.delete(`${process.env.REACT_APP_BACKEND_URL}/files`, {
            params: { file_id, course_id }
        });
        return response.data;
    } catch (error) {
        console.error('Error deleting file:', error);
        throw error;
    }
}

export const loadCourseFiles = async (id: string): Promise<CourseFile[]> => {
    try {
        const response = await authedAxios.get(`${process.env.REACT_APP_BACKEND_URL}/files/${id}`);
        return response.data.files;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}

export const summarizeWeek = async (id: string, week_number: number): Promise<Boolean> => {
    try {
        const response = await authedAxios.post(`${process.env.REACT_APP_BACKEND_URL}/courses/${id}/summarize-week`, { week_number: week_number });
        return response.status === 200;
    } catch (error) {
        console.error('Error fetching week summary:', error);
        throw error;
    }
};


export const fillOutAssignmentWorkflowStep = async (
    courseId: string,
    stepIndex: number,
    stepInput: string,
    currentState: string,
    previousSteps: string[],
    goalOfStep: string,
    summary: string,
    onChunkReceived: (chunk: string) => void) => {
    try {
        const token = localStorage.getItem('access_token');
        if (!token) {
            throw new Error("No access token available");
        }
        const textDecoder = new TextDecoder();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/courses/${courseId}/assignment-workflow/${stepIndex}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'text/event-stream',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    step_input: stepInput,
                    current_state: currentState,
                    previous_steps: previousSteps,
                    goal_of_step: goalOfStep,
                    summary: summary
                })
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body?.getReader();
        if (!reader) {
            throw new Error('Response body is not readable');
        }

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            const chunk = textDecoder.decode(value, { stream: true });
            onChunkReceived(chunk);
        }
    } catch (error) {
        throw new Error('Failed to fill out with Atla. Please try again.');
    }
};

export const finalizeAssignment = async (
    courseId: string,
    stepSpecifiers: string[],
    summary: string,
    onChunkReceived: (chunk: string) => void) => {
    try {
        const token = localStorage.getItem('access_token');
        if (!token) {
            throw new Error("No access token available");
        }
        const textDecoder = new TextDecoder();
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/courses/${courseId}/finalize-assignment`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'text/event-stream',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    summary: summary,
                    step_specifiers: stepSpecifiers,
                })
            }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body?.getReader();
        if (!reader) {
            throw new Error('Response body is not readable');
        }

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            const chunk = textDecoder.decode(value, { stream: true });
            onChunkReceived(chunk);
        }
    } catch (error) {
        throw new Error('Failed to generate assignment. Please try again.');
    }
};

export async function extractAudioAndConvertToAudioFile(videoFile: UploadFile): Promise<UploadFile> {
    // Extract audio from the video file
    const audioBuffer = await extractAudioFromVideo(videoFile);

    // Float32Array samples
    const [left, right] = [audioBuffer.getChannelData(0), audioBuffer.getChannelData(1)]

    // interleaved
    const interleaved = new Float32Array(left.length + right.length)
    for (let src = 0, dst = 0; src < left.length; src++, dst += 2) {
        interleaved[dst] = left[src]
        interleaved[dst + 1] = right[src]
    }

    // get WAV file bytes and audio params of your audio source
    const wavBytes = getWavBytes(interleaved.buffer, {
        isFloat: true,       // floating point or 16-bit integer
        numChannels: 2,
        sampleRate: 48000,
    })
    const audioBlob = new Blob([wavBytes], { type: 'audio/wav' })

    // // Create a File object from the Blob
    const audioFile = blobToFile(audioBlob, videoFile.name.replace(/\.[^/.]+$/, ".wav"));

    return audioFile;
}

async function extractAudioFromVideo(videoFile: UploadFile): Promise<AudioBuffer> {
    return new Promise<AudioBuffer | PromiseLike<AudioBuffer>>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
            try {
                const audioContext = new (window.AudioContext)();
                const audioBuffer = await audioContext.decodeAudioData(event.target?.result as ArrayBuffer);
                resolve(audioBuffer);
            } catch (error) {
                reject(error);
            }
        };
        reader.onerror = reject;

        // videoFile extends File and thus Blob
        // @ts-ignore
        reader.readAsArrayBuffer(videoFile);
    });
}

function blobToFile(blob: Blob, fileName: string): UploadFile {
    return Object.assign(new File([blob], fileName, { type: blob.type }));
}

// Returns Uint8Array of WAV bytes
function getWavBytes(buffer: ArrayBuffer, options: { isFloat: Boolean; numChannels?: number; sampleRate?: number; }) {
    const type = options.isFloat ? Float32Array : Uint16Array
    const numFrames = buffer.byteLength / type.BYTES_PER_ELEMENT

    const headerBytes = getWavHeader(Object.assign({}, options, { numFrames }))
    const wavBytes = new Uint8Array(headerBytes.length + buffer.byteLength);

    // prepend header, then add pcmBytes
    wavBytes.set(headerBytes, 0)
    wavBytes.set(new Uint8Array(buffer), headerBytes.length)

    return wavBytes
}

// adapted from https://gist.github.com/also/900023
// returns Uint8Array of WAV header bytes
function getWavHeader(options: { isFloat: Boolean; numChannels?: number | undefined; sampleRate?: number | undefined; } & { numFrames: number; }) {
    const numFrames = options.numFrames
    const numChannels = options.numChannels || 2
    const sampleRate = options.sampleRate || 44100
    const bytesPerSample = options.isFloat ? 4 : 2
    const format = options.isFloat ? 3 : 1

    const blockAlign = numChannels * bytesPerSample
    const byteRate = sampleRate * blockAlign
    const dataSize = numFrames * blockAlign

    const buffer = new ArrayBuffer(44)
    const dv = new DataView(buffer)

    let p = 0

    function writeString(s: string) {
        for (let i = 0; i < s.length; i++) {
            dv.setUint8(p + i, s.charCodeAt(i))
        }
        p += s.length
    }

    function writeUint32(d: number) {
        dv.setUint32(p, d, true)
        p += 4
    }

    function writeUint16(d: number) {
        dv.setUint16(p, d, true)
        p += 2
    }

    writeString('RIFF')              // ChunkID
    writeUint32(dataSize + 36)       // ChunkSize
    writeString('WAVE')              // Format
    writeString('fmt ')              // Subchunk1ID
    writeUint32(16)                  // Subchunk1Size
    writeUint16(format)              // AudioFormat https://i.sstatic.net/BuSmb.png
    writeUint16(numChannels)         // NumChannels
    writeUint32(sampleRate)          // SampleRate
    writeUint32(byteRate)            // ByteRate
    writeUint16(blockAlign)          // BlockAlign
    writeUint16(bytesPerSample * 8)  // BitsPerSample
    writeString('data')              // Subchunk2ID
    writeUint32(dataSize)            // Subchunk2Size

    return new Uint8Array(buffer)
}