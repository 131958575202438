

interface WorkflowStep {
    title: string;
    goal: string;
    placeholder: string;
    required: boolean;
}

export const assignmentWorkflowSteps: WorkflowStep[] = [
    {
        title: "1: Learning Objective – Core Focus",
        goal: "Establish a clear, measurable outcome that the assignment is designed to achieve.",
        placeholder: "Example: The objective of this assignment is to enable students to demonstrate their understanding of [Concept/Skill] by applying it in [Module/Topic].",
        required: true,
    },
    {
        title: "2. Assignment Format – Structure and Approach",
        goal: "Determine the assignment type (e.g., research project, reflection essay) and ensure it aligns with the learning goals.",
        placeholder: "Example: This assignment will be structured as a {Task Type}, requiring students to {Primary Action} using {Resources}.",
        required: true,
    },
    {
        title: "3. Context Connection – World Relevance",
        goal: "Enhance engagement by connecting the assignment to real-world situations or personal experiences.",
        placeholder: "Example: Students are encouraged to relate this assignment to their {Experiences/Community Issues}, showcasing how {Concept/Skill} applies to {Real-World Context}.",
        required: false,
    },
    {
        title: "4. Context Connection – World Relevance",
        goal: "Encourage responsible use of AI and other tools, fostering reflection on how technology impacts learning and research.",
        placeholder: "Example: Students will reflect on their use of {Tools/AI}, demonstrating critical application in their work.",
        required: false,
    },
    {
        title: "5. Grading Rubrics – Clear Expectations",
        goal: "Define the criteria for evaluating students' work, providing transparency and guidance for expectations.",
        placeholder: "This assignment will be evaluated based on {Key Criteria}, with a weighted breakdown of {% for each criterion}.",
        required: true,
    },
    {
        title: "6. Critical Thinking – Deep Engagement",
        goal: "Prompt students to reflect on and analyze complex issues, fostering deeper engagement with the material.",
        placeholder: "To encourage critical thinking, students will respond to questions like: {Key Questions}, reflecting on real-world challenges.",
        required: false,
    },
    {
        title: "7. Deliverables & Deadlines – Defined Outputs",
        goal: "Specify the type, format, and deadlines for deliverables to ensure accountability and clarity.",
        placeholder: "This assignment requires {#} deliverables, including a {Type of Deliverable} due by {Deadline}.",
        required: true,
    }
];