import React, { useRef, useState } from 'react';
import { Modal, Button, message, Collapse, Typography, Checkbox, Tooltip, theme } from 'antd';
import { BulbOutlined, LeftOutlined, LoadingOutlined, UpCircleFilled, UpCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { assignmentWorkflowSteps } from './asignment-workflow-steps';
import { fillOutAssignmentWorkflowStep, finalizeAssignment } from '../../services/courses';
import { hexToRGBA } from '../../utils/utils';
import { default_theme_components } from '../../config';
import { SendButton, SendContainer, SendInput } from '../basic/send-container';
import { AtlaLogo } from '../session-styles';
import { FlexMarginButton } from '../basic/buttons';
import TiptapEditor from '../basic/TipTapEditor';
import { isEmptyOrOnlyTags } from '../../utils/string_functions';

const { Panel } = Collapse;
const { Text } = Typography;

const FadeContainer = styled.div<{ visible: boolean }>`
  transition: opacity 0.3s ease-in-out;
  opacity: ${props => (props.visible ? 1 : 0)};
  display: ${props => (props.visible ? 'block' : 'none')};
`;

const AtlaInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
`;

const StepTitleBox = styled.div`
  background-color: ${hexToRGBA(default_theme_components.Menu.colorItemBgSelected, 0.1)};
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;


interface AssignmentOverlayProps {
    visible: boolean;
    onClose: () => void;
    onSetAssignment: (assignment: string) => void;
    moduleNumber: number;
    courseId: string;
    weekIndex: number;
    moduleSummary: string;
}

const AssignmentOverlay: React.FC<AssignmentOverlayProps> = ({
    visible,
    onClose,
    onSetAssignment,
    moduleNumber,
    courseId,
    weekIndex,
    moduleSummary,
}) => {
    const { token } = theme.useToken();
    const [assignmentText, setAssignmentText] = useState<string>('');
    const [stepOutputs, setStepOutputs] = useState<string[]>(Array(assignmentWorkflowSteps.length).fill(''));
    const [skippedSteps, setSkippedSteps] = useState<boolean[]>(assignmentWorkflowSteps.map(step => false));
    const [isFillingOut, setIsFillingOut] = useState<boolean[]>(Array(assignmentWorkflowSteps.length).fill(false));
    const [isFinalizingAssignment, setIsFinalizingAssignment] = useState<boolean>(false);
    const [showTextArea, setShowTextArea] = useState(false);
    const [atlaInputs, setAtlaInputs] = useState<string[]>(Array(assignmentWorkflowSteps.length).fill(''));
    const [activeKey, setActiveKey] = useState<string | string[]>(['0']);
    const editorRefs = useRef<any[]>(Array(assignmentWorkflowSteps.length).fill(null));

    const handleSetDirectly = () => {
        if (isEmptyOrOnlyTags(assignmentText)) {
            message.warning('The assignment text cannot be empty.');
            return;
        }
        onSetAssignment(assignmentText);
        onClose();
    };

    const handleKeyDown = (index: number, e: any) => {
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault(); // Prevent the default action (new line)
            handleAtlaSend(index);
        }
    };

    const handleAtlaInputChange = (index: number, value: string) => {
        const newAtlaInputs = [...atlaInputs];
        newAtlaInputs[index] = value;
        setAtlaInputs(newAtlaInputs);
    };

    const handleAtlaSend = async (index: number) => {
        if (isEmptyOrOnlyTags(atlaInputs[index])) return;

        const newIsFillingOut = [...isFillingOut];
        newIsFillingOut[index] = true;
        setIsFillingOut(newIsFillingOut);

        try {
            let accumulatedResponse = '';
            await fillOutAssignmentWorkflowStep(
                courseId,
                index,
                atlaInputs[index],
                stepOutputs.slice(index, index + 1)[0],
                stepOutputs.slice(0, index),
                assignmentWorkflowSteps[index].goal,
                moduleSummary,
                (chunk: string) => {
                    accumulatedResponse += chunk;
                    if (editorRefs.current[index] && editorRefs.current[index].editor) {
                        const editor = editorRefs.current[index].editor;
                        editor.commands.setContent(accumulatedResponse);
                    }
                });
            
            // Update the stepOutputs state after streaming is complete
            setStepOutputs(prev => {
                const newInputs = [...prev];
                newInputs[index] = accumulatedResponse;
                return newInputs;
            });

            // Clear the input after successful send
            handleAtlaInputChange(index, '');
        } catch (error) {
            message.error('Failed to fill out with Atla. Please try again.');
        } finally {
            const newIsFillingOut = [...isFillingOut];
            newIsFillingOut[index] = false;
            setIsFillingOut(newIsFillingOut);
        }
    };

    const handleFinalizingAssignment = async () => {
        setIsFinalizingAssignment(true);
        try {
            setShowTextArea(true);

            const stepSpecifiers = stepOutputs.map((output, index) => {
                if (!skippedSteps[index]) {
                    return `Goal:\n${assignmentWorkflowSteps[index].goal}\nStep Input:\n${output}`;
                }
                return '';
            }).filter(Boolean); // Remove null values

            let accumulatedResponse = '';
            await finalizeAssignment(
                courseId,
                stepSpecifiers,
                moduleSummary,
                (chunk: string) => {
                    accumulatedResponse += chunk;
                    setAssignmentText(accumulatedResponse);
                });
        } catch (error) {
            message.error('Failed to generate assignment. Please try again.');
        } finally {
            setIsFinalizingAssignment(false);
        }
    };

    const handleStepSkip = (index: number, skipped: boolean) => {
        setSkippedSteps(prev => {
            const newSkipped = [...prev];
            newSkipped[index] = skipped;
            return newSkipped;
        });
    };

    const handleSave = (index: number) => {
        if (isEmptyOrOnlyTags(stepOutputs[index])) {
            message.warning('Please fill out the step before saving.');
            return;
        }

        const nextIndex = index + 1;
        if (nextIndex < assignmentWorkflowSteps.length) {
            setActiveKey([nextIndex.toString()]);
        } else {
            setActiveKey([]);
        }
    };

    const isGenerateDisabled = stepOutputs.some((input, index) =>
        assignmentWorkflowSteps[index].required && !skippedSteps[index] && isEmptyOrOnlyTags(input)
    );

    return (
        <Modal
            title={`Assignment Workflow | Module ${moduleNumber}`}
            open={visible}
            onCancel={onClose}
            footer={null}
            width={800}
        >
            <FadeContainer visible={!showTextArea}>
                <Collapse defaultActiveKey={[0]} activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
                    {assignmentWorkflowSteps.map((step, index) => (
                        <Panel
                            header={
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    <span>{step.title + " - " + step.goal}</span>
                                    <div
                                        style={{
                                            minWidth: '15%',
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                        <Text
                                            style={{
                                                marginRight: '10px',
                                                color: isEmptyOrOnlyTags(stepOutputs[index]) ? "orange" : "green"
                                            }}>
                                            {step.required ?
                                                (isEmptyOrOnlyTags(stepOutputs[index]) ? 'Required' : 'Done')
                                                : ''}
                                        </Text>
                                        {!step.required && (
                                            <Checkbox
                                                checked={skippedSteps[index]}
                                                onChange={(e) => handleStepSkip(index, e.target.checked)}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <Text style={{ color: token.colorPrimaryHover }}>Skip</Text>
                                            </Checkbox>
                                        )}
                                    </div>
                                </div>
                            }
                            key={index}
                        >
                            <StepTitleBox>
                                <BulbOutlined style={{
                                    marginRight: '8px',
                                    color: default_theme_components.Menu.colorItemBgSelected,
                                    fontSize: '24px'
                                }} />
                                <Text strong>{step.goal}</Text>
                            </StepTitleBox>
                            <div>
                                <TiptapEditor
                                    ref={el => editorRefs.current[index] = el}
                                    rows={10}
                                    value={stepOutputs[index]}
                                    onChange={(value) => {
                                        const newOutputs = [...stepOutputs];
                                        newOutputs[index] = value;
                                        setStepOutputs(newOutputs);
                                    }}
                                    placeholder={'State your answer directly here ' + step.placeholder}
                                    disabled={(skippedSteps[index] && !step.required) || isFillingOut[index]}
                                    streaming={isFillingOut[index]}
                                />
                            </div>
                            <AtlaInputContainer>
                                <AtlaLogo />
                                <SendContainer>
                                    <div style={{ position: "relative" }}>
                                        <SendInput
                                            size="large"
                                            autoSize={{ minRows: 1, maxRows: 6 }}
                                            value={atlaInputs[index]}
                                            onChange={(e) => handleAtlaInputChange(index, e.target.value)}
                                            onKeyDown={e => handleKeyDown(index, e)}
                                            placeholder="Or tell Atla briefly, what you need and let her fill it out"
                                        />
                                        <Tooltip
                                            title={isFillingOut[index] ? "Please wait while the message is being processed" : ""}
                                        >
                                            <SendButton
                                                data-testid="send-button"
                                                size="large"
                                                type="text"
                                                onClick={() => handleAtlaSend(index)}
                                                disabled={(skippedSteps[index] && !step.required) || isFillingOut[index]}
                                                style={{
                                                    transition: 'background-color 0.3s ease',
                                                    color: token.colorPrimary,
                                                    position: 'absolute'
                                                }}
                                                onMouseEnter={(event) => {
                                                    event.currentTarget.style.backgroundColor = 'transparent';
                                                    event.currentTarget.style.color = token.colorPrimaryHover;
                                                }}
                                                onMouseLeave={(event) => {
                                                    event.currentTarget.style.backgroundColor = 'transparent';
                                                    event.currentTarget.style.color = token.colorPrimary;
                                                }}
                                            >
                                                {isFillingOut[index]
                                                    ? <LoadingOutlined style={{ color: token.colorPrimary }} /> : atlaInputs[index].length > 0
                                                        ? <UpCircleFilled style={{ fontSize: '24px' }} />
                                                        : <UpCircleOutlined style={{ fontSize: '24px' }} />}
                                            </SendButton>
                                        </Tooltip>
                                    </div>
                                </SendContainer>
                            </AtlaInputContainer>
                            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                                <FlexMarginButton
                                    onClick={() => handleSave(index)}
                                >
                                    Next step
                                </FlexMarginButton>
                            </div>
                        </Panel>
                    ))}
                </Collapse>
                <div style={{
                    marginTop: 20,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Button onClick={() => setShowTextArea(true)}>
                        Set Directly
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleFinalizingAssignment}
                        disabled={isGenerateDisabled}
                        loading={isFinalizingAssignment}
                    >
                        Generate
                    </Button>
                </div>
            </FadeContainer>
            <FadeContainer visible={showTextArea}>
                <TiptapEditor
                    rows={10}
                    value={assignmentText}
                    onChange={(value) => setAssignmentText(value)}
                    placeholder="This module's assignment is..."
                />
                <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
                    <Button icon={<LeftOutlined />} onClick={() => setShowTextArea(false)}>
                        Go back
                    </Button>
                    <Button type="primary" onClick={handleSetDirectly}>
                        Finalize Assignment
                    </Button>
                </div>
            </FadeContainer>
        </Modal>
    );
};

export default AssignmentOverlay;