import { SettingOutlined, UserOutlined, BarChartOutlined, SignatureOutlined } from '@ant-design/icons';
import { styled } from '@stitches/react';
import { Avatar, Popover, theme } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Professor } from '../../services/courses';
import { newConversation } from '../../services/conversations';
import { getUserDataFromLocalStorage } from '../../utils/useLocalStorage';
import { hexToRGBA } from '../../utils/utils';
import { NoMarginButton } from '../basic/buttons';
import { colorAtla, colorSona } from '../../config';

interface CourseCardProps {
    id: string;
    title: string;
    description: string;
    professors: Professor[];
    lastUpdated: string;
    cta: {
        text: string;
        icon: React.ReactElement;
        onClick: () => void;
    };
}

const Card = styled("div", {
    width: "100%",
    height: "100%",
    padding: "20px",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    borderRadius: "8px",
    background: "rgba(255, 255, 255, 0.05)",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    justifyContent: "space-between",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    transition: "0.5s",
    margin: "0 auto", // Centers the card in the grid cell
    "&:hover": {
        boxShadow: "0 12px 24px 0 rgba(0,0,0,0.2)"
    },
    '@media (max-width: 768px)': {
        width: '90%', // Allows the card to take more space on smaller screens
        margin: "20px", // Adds some margin on smaller screens
    }
});

const InnerCard = styled("div", {
    border: "1px solid #FFFFFF1A", // Changed to a transparent-ish border
    borderRadius: "8px",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: "30px",
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "100%",
    alignItems: "center",
    padding: "5px 15px",
    margin: "10px 0",
    transition: "0.5s",
    "&:hover": {
        boxShadow: "0 12px 24px 0 rgba(0,0,0,0.2)" // Increased shadow on hover
    }
})

const SonaAtlaCard = styled(InnerCard, {
    variants: {
        color: {
            sona: {
                borderColor: colorSona,
                '&:hover': {
                    boxShadow: `0 8px 12px -4px ${colorSona}`
                }
            },
            atla: {
                borderColor: colorAtla,
                '&:hover': {
                    boxShadow: `0 8px 12px -4px ${colorAtla}`
                }
            },
        }
    },
    defaultVariants: {
        color: 'blue'
    }
});


const SonaAtlaTitle = styled("h3", {
    fontSize: "20px",
    fontWeight: "bold",
    width: '40%',
})

const TitleContainer = styled("div", {
    color: "white",
    borderRadius: "8px",
    padding: "10px",
    minHeight: "75px",
    display: "flex",
    wordWrap: "break-word",
})

const ContentContainer = styled("div", {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    wordBreak: "break-word",  // Changed from "break-all" to "break-word"
    overflowWrap: "break-word",  // Added to ensure long words wrap
    hyphens: "auto",  // Optional: adds hyphens to very long words if needed
})

const Title = styled("h3", {
    color: "rgba(249,249,249,1)",
    fontSize: "20px",
    fontWeight: "bold",
    margin: "0",
    alignSelf: "flex-end",
    maxWidth: "100%"
})

const CourseCard: React.FC<CourseCardProps> = ({ id, title, description, professors, lastUpdated, cta }) => {
    const { token } = theme.useToken();
    const user = getUserDataFromLocalStorage();
    const navigate = useNavigate()
    const date = new Date(lastUpdated);
    const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;

    return (
        <Card>
            <TitleContainer style={{ background: token.colorBgSpotlight }}>
                <Title>
                    {title}
                </Title>
            </TitleContainer>
            <ContentContainer>
                <div>
                    <p><b>Description</b></p>
                    <p>{description}</p>
                </div>
                <div>
                    <InnerCard style={{ background: hexToRGBA(token.colorBgSpotlight, 0.1) }}>
                        <p>Professors</p>
                        <Avatar.Group>
                            {professors.map((professor) => (
                                <Popover placement="right" title={"Prof. " + professor.name} content={<small><i>Tell us in the feedback form what you'd like to see here!</i></small>}>
                                    <Avatar size="large" src={professor.photo} icon={<UserOutlined />} />
                                </Popover>
                            ))}
                        </Avatar.Group>
                    </InnerCard>
                    <InnerCard style={{ background: hexToRGBA(token.colorBgSpotlight, 0.1) }}>
                        <strong>Last Update</strong>
                        <p>{formattedDate}</p>
                    </InnerCard>
                    <SonaAtlaCard color="sona"
                        style={{
                            background: hexToRGBA(token.colorBgSpotlight, 0.1),
                        }}
                    >
                        <SonaAtlaTitle style={{
                            color: token.colorText
                        }}>
                            Sona
                        </SonaAtlaTitle>
                        <div style={{ textAlign: "right", width: '50%' }}>
                            <NoMarginButton
                                onClick={cta.onClick}
                                size="large"
                                type="primary"
                                icon={cta?.icon}
                                style={{ width: "100%", marginTop: '2px', marginBottom: '2px' }}>
                                {cta?.text}
                            </NoMarginButton>
                            {user!['type'] === "professor" || user!['type'] === "admin"
                                ? <NoMarginButton
                                    onClick={() => navigate(`/courses/${id}`)}
                                    size="large"
                                    icon={<SettingOutlined />}
                                    style={{ width: "100%", marginTop: '2px', marginBottom: '2px' }}>
                                    {"Edit"}
                                </NoMarginButton>
                                : <></>
                            }
                        </div>
                    </SonaAtlaCard>
                    {(user!['type'] === "professor" || user!['type'] === "admin") && (
                        <SonaAtlaCard
                            color="atla"
                            style={{
                                background: hexToRGBA(token.colorBgSpotlight, 0.1)
                            }}
                        >
                            <SonaAtlaTitle style={{
                                color: token.colorText
                            }}>
                                Atla
                            </SonaAtlaTitle>
                            <div style={{ textAlign: "right", width: '50%' }}>
                                <NoMarginButton
                                    onClick={() => { newConversation(id, user._id, "analytics").then((chat) => navigate(`/analytics-copilot/${chat._id}`)) }}
                                    size="large"
                                    type="primary"
                                    icon={<SignatureOutlined />}
                                    style={{ width: "100%", marginTop: '2px', marginBottom: '2px' }}>
                                    {"Interact"}
                                </NoMarginButton>
                                <NoMarginButton
                                    onClick={() => navigate(`/analytics/${id}`)}
                                    size="large"
                                    icon={<BarChartOutlined />}
                                    style={{ width: "100%", marginTop: '2px', marginBottom: '2px' }}>
                                    {"Analyze"}
                                </NoMarginButton>
                            </div>
                        </SonaAtlaCard>
                    )}
                </div>
            </ContentContainer >
        </Card >
    );
};

export default CourseCard;
