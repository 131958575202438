import { LaptopOutlined } from "@ant-design/icons";
import { styled } from "@stitches/react";
import { TopImages } from "../components/session-styles";
import { AbsoluteCenter } from "../components/styles";
import { useEffect, useRef } from "react";

const FullPage = styled('div', {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000,
    background: 'black',
    width: "100vw",
    height: "100vh",
    overflow: 'hidden', // Prevent scrolling
    touchAction: 'none', // Prevent zooming and panning on touch devices
    '@media (min-width: 1024px)': {
        display: 'none',
    },
})
const HiddenWhenMobile = () => {
    const fullPageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const preventZoom = (e: KeyboardEvent | WheelEvent) => {
            if (('ctrlKey' in e && e.ctrlKey) || ('metaKey' in e && e.metaKey)) { // Check if Ctrl or Cmd key is pressed
                e.preventDefault();
            }
        };

        const currentRef = fullPageRef.current;

        if (currentRef) {
            currentRef.addEventListener('wheel', preventZoom, { passive: false });
            currentRef.addEventListener('keydown', preventZoom, { passive: false });
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('wheel', preventZoom);
                currentRef.removeEventListener('keydown', preventZoom);
            }
        };
    }, []);

    return (
        <FullPage ref={fullPageRef}>
            <TopImages />
            <AbsoluteCenter style={{ textAlign: "center" }}>
                <p style={{
                    color: '#FFFFFF'
                }}>
                    For now, our world of learning is tailored for desktop screens
                </p>
                <LaptopOutlined style={{ color: '#FFFFFF', fontSize: "100px" }} />
                <p style={{
                    color: '#FFFFFF'
                }}><strong>
                        Please join us there for the best experience!
                    </strong></p>
            </AbsoluteCenter>
        </FullPage>
    );
};

export default HiddenWhenMobile;

